import React from 'react'
import styled from 'styled-components';
import { Button } from './Button';

const Proposal = () => {
    return (
        <ProposalContainer>
            <ProposalHeader>Talk to Us</ProposalHeader>
            <ProposalWrapper>
                <ProposalCard>
                    <ProposalText>After Submitting your request. Our representative will get in touch as soon as possible</ProposalText>
                </ProposalCard>
                <form name="proposal request" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                    <input type="hidden" name="form-name" value="proposal request" />
                    <FormWrap>
                        <label htmlFor="name">
                            <input type="text" placeholder="Enter Your Name" id="name" name="name" />
                        </label>
                        <label htmlFor="email">
                            <input type="email" placeholder="Enter Your Email" id="email" name="email" />
                        </label>
                        {/* <label htmlFor="Contact Number">
                            <input type="text" placeholder="Contact Number" id="contact" name="contact" />
                        </label> */}
                        {/* <label htmlFor="budget">
                            <input type="text" placeholder="Approximate Budget" id="budget" name="budget" />
                        </label> */}
                        {/* <label htmlFor="feedback">
                            <input type="text" placeholder="How did you hear about us" id="feedback" name="feedback" />
                        </label> */}
                        <label htmlFor="description">
                            <input type="textarea" placeholder="message" id="description" name="description" />
                        </label>
                        <Button as="button" type="submit" primary="true" css={`width: 100%; height: 50px;`}>Send</Button>
                    </FormWrap>
                </form>
            </ProposalWrapper>
        </ProposalContainer>
    )
}

export default Proposal


const ProposalContainer = styled.div`
    min-height: 100vh;
    width:100%;
    padding: 5rem calc((100vw - 1300px) / 2);
    background: #3D6A78;
    color: #fff;
    margin-top: -10px;
`

const ProposalHeader = styled.h2`
    text-align: center;
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 3rem; 
`

const ProposalWrapper = styled.div`
    display:grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    justify-items: center;
    padding: 0 2rem;
    width: 100%;

    @media screen and (min-width: 800px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        }
`
const ProposalCard = styled.div`
    line-height: 2;
    width: 50%;
    position: relative;
    transition: 0.2s ease;
    align-self: center;   
`

const ProposalText = styled.p`
        text-align: center;
        margin-top: auto;   
        font-size: clamp(1rem,2vw, 1.25rem); 
        margin-bottom: 2rem;    
`

const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

 input {
     padding: 1rem 1,5rem;
     outline: none;
     width: 100%;
     height: 50px;
     border-radius: 20px;
     border: none;
     margin-bottom: 1rem;
     text-align: center;

     @media screen and (min-width: 400px) {
        width: 20rem;
        }
 }

 input[type=textarea] {
    height: 200px;
 }
`
