import React from 'react'
import styled from 'styled-components';
import Layout from '../components/layout'
import SEO from '../components/seo'
import Proposal from '../components/Proposal'

const Contact = () => (
    <Layout>
        <SEO title="Request For Proposal from Tripleaim software"
            description="Request for a consultation from a health software company focusing on improving healthcare businesses through artificial intelligent software solutions."
        ></SEO>
        <Container>
            <HeroSection>Request for a Proposal</HeroSection>
            <Proposal />
        </Container>
    </Layout>
)
export default Contact

const Container = styled.div`
    margin-top: 70px;
`
const HeroSection = styled.h1`
    background: linear-gradient(to bottom, #1C333A 10%,#1B353C 50%,#1D3238 70%, #187E9C 100%);
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
    height: 25vh;
    padding: 0 1rem;
    font-size: clamp(1.5rem,6vw, 3rem);
`
